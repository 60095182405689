// src/composables/useAuth.js
import Cookies from 'js-cookie';
import { computed } from 'vue';

export const isAuth = () => {
    try{
    const isAuthenticated = computed(() => {
        return !!Cookies.get('access_token');
    });

    if (!isAuthenticated.value) {
        const signInButton = document.querySelector('#signin_key');
        if (signInButton) {
        signInButton.click();
        }
        return false;
    }
    return true;
}catch(error){
    console.error('Error break/get:', error);
    }
}