 <template>
  <div class="card z-index-2 p-3">
     <div class="pb-0 border-radius-xs border-radius-md border-radius-lg">
          <div class="d-flex justify-content-between">
            <h6 class="pb-1">BREAKS</h6>
            <span class="">Total Breaks : {{ recordCount }}</span>
          </div>
           
        </div>
        <breakInfo2 v-if="token && breaks?.length===0"></breakInfo2>
        <breakInfo3 v-if="!token"></breakInfo3>
        <div v-if="breaks?.length===0" class="row">
          <a @click="addBreakManually" class="col-12 text-end text-success p-4 start-link">Add Break</a>
        </div>
         
           
      <div v-if="breaks?.length>0" class="row">
              <div class="mb-3 col-md-4 col-sm-12">
                  <label for="currentDate" class="form-label">Created On</label>
                  <input type="date" class="form-control" v-model="createdDate">
              </div>
              <div class="mb-3 col-md-4 col-sm-12">
                  <label for="type" class="form-label">Break Type</label>
                  <!-- <select class="form-select form-control-alternative" v-model="type"> -->
                    <select v-model="type" class="form-select handle-focus form-control-alternative mb-3">
                        <option v-for="brk in breakTypes" :key="brk" :value="brk">{{ brk }}</option>
                    </select>
              </div>
               <div class="mb-3 col-md-2 col-sm-12 mt-2 pt-4">
                 <button type="button" class="btn btn-sm bg-gradient-success float-start" @click="getBreaks(1)">
                Search
            </button>    
        </div>
            </div>  
        <div class="row" v-if="isLoading">Loading...</div>
        <div class="card"  v-if="!isLoading">
      <div v-if="token" class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0" v-if="breaks.length>0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Break Type
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Reasons
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Created
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Duration(minutes)
              </th>
              
               <th
                class="text-uppercase text-end text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in breaks" :key="index">
              <td>
                <div class="d-flex px-2">
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm handle-large-text" :title="value.type">{{value.type}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0 handle-large-text" :title="value.reason">{{ value.reason }}</p>
              </td>
               <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ convertDate(value.createdDate) }}</span>
              </td> 
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ value.duration }}</span>
              </td> 
               
              <td class="text-end">
                <span class="me-2 mouse-pointer" @click="viewBreak(value)"><i class="custom-icon view-icon"></i></span>
                <span class="me-1 mouse-pointer" @click="editBreak(value)"><i class="custom-icon edit-icon"></i></span>
                <span class="ms-2 mouse-pointer" @click="deleteBreak(value._id)"><i class="custom-icon trash-icon"></i></span>
              </td>
            </tr>
          </tbody>
        </table> 
      </div>
      </div>
      <div class="row">
      <div class="d-inline-block col-12">
        <Pagination v-if="recordCount>10" :records-per-page="recordsPerPage" :record-count="recordCount" @changePage="onChangePage"
          :current-page="pageNumber"></Pagination>
      </div> 
      </div>
  </div> 
  <EditBreak v-if="showEdit" :edit-record="editRecord" is-archives="true" @update:archivesEditBreaks="getBreaks(1)"></EditBreak>
  <ViewBreak v-if="showViewModal" :break-details="selectedBreak" @close="showViewModal = false"></ViewBreak>
</template>

<script setup>
import { onMounted, ref, computed, nextTick} from 'vue'; 
import apiClient from '../../../api/apiClient';
import { convertDate } from "../../utilities/transforms";
import { deleteRecord } from '../composables/deleteRecords';
import { isAuth } from '../composables/userAuth';
import Pagination from './pagination';
import ViewBreak from '../breaks/ViewBreak.vue';
import EditBreak from '../breaks/EditBreak.vue';
import breakInfo2 from "../common/break-info2.vue";
import breakInfo3 from "../common/break-info3.vue";
import Cookies from 'js-cookie';  
const userId = Cookies.get('id');
const recordsPerPage = ref(10);
const token = computed(() => {
      return Cookies.get('access_token');;
  });
let showViewModal = ref(false);
const selectedBreak = ref(null);
let showEdit = ref(false);
let editRecord = ref(null);

  let pageNumber = ref(1);
  let breaks = ref([]);
  let breakTypes = ref([]);
  let recordCount = ref(0);
  let createdDate = ref(null);
  let type = ref('');
  let isLoading = ref(false);
 
  const deleteBreak = async (id) => {
    const repsonseId = await deleteRecord(id, "break");
    console.log("delete response", repsonseId);
    if(repsonseId) { 
      await getBreaks(1);
    }
  }

  const viewBreak = async (task) => { 
     showViewModal.value = false;
    await nextTick(); 
    selectedBreak.value = task;
    showViewModal.value = true;
  };

  const editBreak = async (value) => {
    // shallow copy of the value
    showEdit.value = false;
    await nextTick(); 
    editRecord.value = {...value}
    showEdit.value = true;
  }

  const getBreaks = async (page) => {
  console.log('getBreaks page: ' + type.value);
    try { 
        isLoading.value = true;
        pageNumber.value = page;
        breaks.value = [];
        const limit = recordsPerPage.value;
        const skip = (pageNumber.value - 1) * recordsPerPage.value;
        let params = {
          userId,
          limit,
          skip
        }  
        if (type.value) {
          params.type = type.value;
        }
        if (createdDate.value) {
          params["createdDate"] = createdDate.value;
        }
        const response = await apiClient.get('/break/getArchivesBreaks', {
        params 
      });
      if(response?.data){
      breaks.value = response.data.breaks;
      recordCount.value = response.data.totalCount;
      }
      } catch (error) {
        console.error('Error responseForToday:', error);
      }
        finally { 
        isLoading.value = false;
      }
    } 

    const onChangePage = async (newPage) => {
      breaks.value = [];
      isLoading.value = true;
      window.scrollTo(0, 0);
      await getBreaks(newPage);
    };

    const getBreakTypes = async () => {
    try {  
            const response = await apiClient.get('/type/get', {
            params: {
              userId: userId
            }
          });
          breakTypes.value = response.data[0].type; 
          console.log('type/get:', response.data[0].type);     
          } catch (error) {
            console.error('Error break/get:', error);
          } 
    }

    const addBreakManually = async() => {
      if(isAuth()) { 
        showEdit.value = false;
        await nextTick(); 
        editRecord.value = null;
        showEdit.value = true;
      }
    }
    
    onMounted( async() => {
      await getBreakTypes()
      await getBreaks(1);
    }); 
  
</script>

<style scoped>
.container {
  max-width: 900px;
  margin: auto;
}
textarea {
  resize: none;
}
.start-link {
  text-decoration: underline;
  cursor: pointer;
  color: #86b7fe !important;
}
</style>
