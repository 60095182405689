<template>
  <nav v-if="props.recordCount" aria-label="{{ t('pagination') }}" class="pagination d-flex justify-content-center mt-3 text-center">
    <ul class="pagination">

      <li v-if="props.firstLastButton" @click="setPage(1)" :class="{ 'disabled': internalCurrentPage === 1 }" class="page-item">
        <a class="page-link">{{ props.firstButtonText }}</a>
      </li>
      
      <li v-if="!isMobile" @click="previousPage" :class="{ 'disabled': internalCurrentPage === 1 }" class="page-item">
        <a class="page-link">{{ props.prevText }}</a>
      </li>
      
      <li v-if="visiblePageNumbers[0] > 1" class="page-item">
        <a class="page-link">...</a>
      </li>

      <li v-for="pageNumber in visiblePageNumbers" :key="pageNumber" @click="setPage(pageNumber)" :class="{ 'active': internalCurrentPage === pageNumber }" class="page-item">
        <a class="page-link">{{ pageNumber }}</a>
      </li>

      <li v-if="visiblePageNumbers[visiblePageNumbers.length - 1] < pageCount" class="page-item">
        <a class="page-link">...</a>
      </li>

      <li v-if="!isMobile" @click="nextPage" :class="{ 'disabled': internalCurrentPage === pageCount }" class="page-item">
        <a class="page-link">{{ props.nextText }}</a>
      </li>

      <li v-if="props.lastButtonText" @click="setPage(pageCount)" :class="{ 'disabled': internalCurrentPage === pageCount }" class="page-item">
        <a class="page-link">{{ props.lastButtonText }}</a>
      </li>

    </ul>
  </nav>

</template>

<script setup>
import { computed } from 'vue'; 

const props = defineProps({
  recordCount: { type: Number, required: true },
  recordsPerPage: { type: Number, required: true },
  currentPage: { type: Number, required: true },
  firstLastButton: { type: Boolean, default: true },
  firstButtonText: { type: String, default: 'First' },
  lastButtonText: { type: String, default: 'Last' },
  prevText: { type: String, default: 'Prev' },
  nextText: { type: String, default: 'Next' }
});

const isMobile = computed(() => window.innerWidth <= 800);

const emit = defineEmits(['changePage']);

const internalCurrentPage = computed({
  get() {
    return props.currentPage;
  },
  set(page) {
    emit('changePage', page);
  }
});

const setPage = (page) => {
  internalCurrentPage.value = page;
};

const pageCount = computed(() => Math.ceil(props.recordCount / props.recordsPerPage));

const visiblePageNumbers = computed(() => {
  const totalPages = pageCount.value;
  const currentPage = internalCurrentPage.value;
  const pageRange = isMobile.value?1:2;
  const visiblePages = [];

  let startPage = Math.max(1, currentPage - pageRange);
  let endPage = Math.min(totalPages, currentPage + pageRange);

  if (currentPage - pageRange <= 0) {
    endPage = Math.min(pageRange * 2 + 1, totalPages);
  }

  if (currentPage + pageRange >= totalPages) {
    startPage = Math.max(totalPages - pageRange * 2, 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    visiblePages.push(i);
  }

  return visiblePages;
});

const previousPage = () => {
try{
  if (internalCurrentPage.value > 1) {
    const previousPageCount = internalCurrentPage.value-1;
    setPage(previousPageCount);
  }
  }catch(error){
      console.error('Error break/get:', error);
    }
};

const nextPage = () => {
try{
  if (internalCurrentPage.value < pageCount.value) {
    const nextPageCount = internalCurrentPage.value+1;
    setPage(nextPageCount);
  }
  }catch(error){
      console.error('Error break/get:', error);
      }
};
</script>

<style scoped>
.pagination{
  /* ToDo: change the style  */
  font-size: 20px;
  cursor: pointer;
}
.disabled {
  cursor:default !important;
}
@media (max-width: 800px) {
  .pagination {
    font-size: 14px; 
  }
  .pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;
}
.page-item .page-link {
  padding: 0.5rem 0.75rem;
}
.page-item.disabled .page-link {
  cursor: default;
  opacity: 0.5;
}
}
</style>
