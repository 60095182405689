<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg">Stay Organized – Add Your First Task!</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">It looks like you haven’t added any tasks yet. Staying organized and prioritizing your tasks is crucial for productivity and achieving your goals. Start by entering your tasks—whether they're critical, important, or routine—and set deadlines and priorities to keep track of your progress.
    <br/><br/>
    Our platform provides clear charts and tables to help you visualize your workload and monitor the status of each task. Don’t let your day slip by unorganized—add your first task now and take control of your time and productivity!</p>
  </div>
</div>
</template>
<script setup>
</script>
 
