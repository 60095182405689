import Chart from "chart.js/auto";

export const drawCharts = async(DOMId, records, lable, tetx, type) => {
    const chartKeys = Object.keys(records);
    const chartValues = Object.values(records);
    const ctx = document.getElementById(DOMId).getContext('2d');

    new Chart(ctx, {
        type: type, // doughnut
        data: {
            labels: chartKeys,
            datasets: [{
                label: lable,
                data: chartValues,
                // backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
                borderWidth: 2
            }]
        },
        options: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: tetx
                }
            }
        },
    });
};
