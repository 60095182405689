<template>
  <div ref="modalRef" class="modal fade" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header color-black">
          <h5 class="modal-title">Add Task</h5>
          <button type="button" class="border-0 bg-transparent" @click="closeDialog()" aria-label="Close">
            <i class="custom-icon close-icon icon-lg"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="title" class="form-label">Title</label>
            <input
              type="text"
              class="form-control handle-focus"
              v-model="task.title"
              :class="{ 'is-invalid': validationErrors.title }"
              required
            />
            <div v-if="validationErrors.title" class="invalid-feedback">
              Title is required.
            </div>
          </div>
          <div class="mb-3">
            <label for="description" class="form-label">Description</label>
            <textarea
              class="form-control handle-focus"
              v-model="task.description"
              rows="3"
              :class="{ 'is-invalid': validationErrors.description }"
              required
            ></textarea>
            <div v-if="validationErrors.description" class="invalid-feedback">
              Description is required.
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-4 col-sm-12">
              <label for="currentDate" class="form-label">Schedule (optional)</label>
              <input
                type="date"
                class="form-control handle-focus"
                v-model="task.scheduled"
                :class="{ 'is-invalid': validationErrors.scheduled }"
              />
              <div v-if="validationErrors.scheduled" class="invalid-feedback">
                Please provide a valid date.
              </div>
            </div>
            <div class="mb-3 col-md-4 col-sm-12">
              <label for="type" class="form-label">Task Status</label>
              <select
                class="form-select handle-focus form-control-alternative"
                v-model="task.status"
                :class="{ 'is-invalid': validationErrors.status }"
                required
              >
                <option value="To Do">To Do</option>
                <option value="In Progress">In Progress</option>
                <option value="Completed">Completed</option>
                <option value="Invalid">Invalid</option>
                <option value="On Hold">On Hold</option>
                <option value="Deferred">Deferred</option>
                <option value="Blocked">Blocked</option>
              </select>
              <div v-if="validationErrors.status" class="invalid-feedback">
                Status is required.
              </div>
            </div>
            <div class="mb-3 col-md-4 col-sm-12">
              <label for="type" class="form-label">Priority Levels</label>
              <select
                class="form-select handle-focus form-control-alternative"
                v-model="task.priority"
                :class="{ 'is-invalid': validationErrors.priority }"
                required
              >
                <option value="Normal">Normal</option>
                <option value="Important">Important</option>
                <option value="Critical">Critical</option>
                <option value="Low">Low</option>
                <option value="High">High</option>
                <option value="Urgent">Urgent</option>
                <option value="Blocker">Blocker</option>
              </select>
              <div v-if="validationErrors.priority" class="invalid-feedback">
                Priority is required.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer bg-gradient">
          <button type="button" class="btn btn-sm bg-gradient-success float-start" @click="addTask()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { shallowRef, ref, onMounted } from "vue";
import apiClient from '../../../api/apiClient';
import Cookies from 'js-cookie'; 
import { Modal } from "bootstrap";
import { formatDateToDatetimeLocal } from "../../utilities/transforms";

const userId = Cookies.get('id');
const modalRef = shallowRef(null);
let modal = null;

const task = ref({
  title: '',
  description: '',
  status: 'To Do',
  priority: 'Normal',
  scheduled: null,
  userId: userId
});

const validationErrors = ref({
  title: false,
  description: false,
  status: false,
  priority: false,
  scheduled: false
});

const emit = defineEmits(["update:archivesEditTask"]);

const props = defineProps({
  editRecord: { type: Object, required: false },
  isArchives : { type : Boolean, required:false }
});

if (props.editRecord) {
  task.value = props.editRecord;
}

onMounted(() => {
  console.log("task.value = " + task.value.scheduled);
  if(task.value?.scheduled){
    task.value.scheduled = formatDateToDatetimeLocal(props.editRecord.scheduled);
  }
  console.log("task.value = " + task.value.scheduled);
  modal = new Modal(modalRef.value);
  modal.show('static');
});

const closeDialog = () => {
  modal.hide();
};

const addTask = async () => {
  if (validateForm()) {
    try {
      const editId = task.value._id;
      delete task.value._id;

      if (editId) {
        await apiClient.put(`/task/edit/${editId}`, task.value);
      } else {
        await apiClient.post('/task/add', task.value);
      }
      
      
    } catch (error) {
      console.error('Error:', error);
    } finally {
      modal.hide();
      if(props.isArchives) {
        emit('update:archivesEditTask', true);
      } else {
        location.reload(true);
      }
    }
  }
};

const validateForm = () => {
  let isValid = true;
    validationErrors.value.scheduled = false;
  validationErrors.value.title = !task.value.title.trim();
  validationErrors.value.description = !task.value.description.trim();
  validationErrors.value.status = !task.value.status;
  validationErrors.value.priority = !task.value.priority; 
  if (task.value.scheduled === null || Object.values(validationErrors.value).some(error => error)) {
    isValid = false;
    if(task.value.scheduled === null){
        validationErrors.value.scheduled = true; 
    }
  } 

  return isValid;
};
</script>
