<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <Breaks />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        <Tasks />
      </div>
    </div>
  </div>
</template>

<script setup>
import Breaks from "./components/archives/Breaks.vue";
import Tasks from "./components/archives/Tasks.vue";
import { useStore } from "vuex";
const store = useStore();
store.state.isAbsolute = false;
store.commit("isDashboard", false);
</script>
