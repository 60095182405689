import apiClient from '../../../api/apiClient';

export const getBreaks = async (userId, isToday, isMonth) => {
    try { 
            const response = await apiClient.get('/break/get', {
            params: {
              userId,
              isToday,
              isMonth
            }
          });
          const monthlyData = response.data;
          // breaksList.value = response.data.length;
          const todayDate = getTodayDate();
            const todayData =  monthlyData.filter(dt =>{
              const recordDate = new Date(dt.createdDate);
              recordDate.setUTCHours(0, 0, 0, 0);
               return recordDate.getTime() === todayDate.getTime();
            });

            // Get one week ago data
            const sevenDaysAgo = getDateXDaysAgo(7);
            const oneWeekData = filterByDateRange(monthlyData, sevenDaysAgo, todayDate);

            // Get two weeks ago data
            const fourteenDaysAgo = getDateXDaysAgo(14);
            const twoWeekData = filterByDateRange(monthlyData, fourteenDaysAgo, sevenDaysAgo);

             // Get three weeks ago data
            const twentyOneDaysAgo = getDateXDaysAgo(14);
            const threeWeekData = filterByDateRange(monthlyData, twentyOneDaysAgo, fourteenDaysAgo);


             // Get two weeks ago data
            const twentyEightDaysAgo = getDateXDaysAgo(14);
            const fourWeekData = filterByDateRange(monthlyData, twentyEightDaysAgo, twentyOneDaysAgo);

            // Calculate the sum of durations for last 7 days(one week) and previous 7 days(two week)
            const sumDurations = (data) => data.reduce((sum, record) => sum + record.duration, 0);

            const oneWeekMinutes = sumDurations(oneWeekData);
            const twoWeekMinutes = sumDurations(twoWeekData);

            // Calculate percentage change between the last 7 days and the previous 7 days
            const percentage1To2WeekChange = twoWeekMinutes !== 0
              ? ((oneWeekMinutes - twoWeekMinutes) / twoWeekMinutes) * 100
              : oneWeekMinutes * 100;

            const oneToTwoWeekPercentage = percentage1To2WeekChange > 0 ? `+${percentage1To2WeekChange.toFixed(2)}%` : `${percentage1To2WeekChange.toFixed(2)}%`;

            // Output the results
            console.log('Today\'s Data:', todayData);
            console.log('Last 7 Days Data:', oneWeekData);
            console.log('Previous 7 Days Data:', twoWeekData);
            console.log('Percentage Change:', oneToTwoWeekPercentage);
            console.log('Last 7 Days Mean toneWeekMinutes:', oneWeekMinutes);
            console.log('Previous 7 Days Mean twoWeekMinutes:', twoWeekMinutes);
                // const percentageChange = ((breaksThisWeek.length - fourteenDaysBreaks.length) / fourteenDaysBreaks.length) * 100;
            console.log('responseForthis month:', response.data);     
            return {monthlyData, todayData, oneWeekData, twoWeekData, threeWeekData, fourWeekData, oneToTwoWeekPercentage, oneWeekMinutes, twoWeekMinutes }
          } catch (error) {
            console.error('Error responseForToday:', error);
          }
          //  finally { 
          //   }
    } 
// Helper function to filter data based on date range
    const filterByDateRange = (records, startDate, endDate) => {  
      try{
      return records.filter(record => {
        const recordDate = new Date(record.createdDate);
        return recordDate > startDate && recordDate <= endDate;
      });
    }catch(error){
      console.error('Error break/get:', error);
      }
    }

    // Helper function to get today's date at midnight
    const getTodayDate =() => {
      try{
      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);
      return today;
    }catch(error){
      console.error('Error break/get:', error);
      }
    }

    // Helper function to get date X days ago
    const getDateXDaysAgo = (days) => {
      try{
      const date = new Date();
      date.setDate(date.getDate() - days);
      date.setUTCHours(0, 0, 0, 0);
      return date;
    }catch(error){
      console.error('Error break/get:', error);
      }
    }