<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg">Take Control of Your Time – Log Your Breaks!</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">Take control of your day by logging your breaks and maintaining a healthy work-life balance. Our platform allows you to track your breaks—whether it’s a quick coffee or a lunch break—and provides detailed charts and insights to help you manage your time effectively.
    <br/><br/>
      To access this feature and start logging your breaks, please <a @click="openSignIn" class="cursor-pointer text-success start-link">sign in</a> or create an account. Join us now to begin tracking your breaks and improve your productivity with a simple and intuitive break management system.
</p>
  </div>
</div>
</template>
<script setup>
const openSignIn = () => {
try{
  const signInKey = document.querySelector('#signin_key');
      signInKey.click();
    }catch(error){
      console.error('Error break/get:', error);
      }
  }
</script>
 
<style scoped>
 
.start-link {
  text-decoration: underline;
  cursor: pointer;
  color: #86b7fe !important;
}
</style>