<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg">Maximize Your Productivity – Start Managing Your Tasks Today!</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">Stay ahead of your goals by organizing your tasks efficiently. Whether it's an important project or a simple daily task, our platform helps you manage your workload with ease. By logging in, you can start adding tasks, set priorities, and track your progress with detailed charts and insights.
    <br/><br/>
  <a @click="openSignIn" class="cursor-pointer text-success start-link">Sign in</a> or create an account to unlock powerful task management features, enabling you to stay focused and productive. Join us today and get started on the path to better organization and time management.</p>
  </div>
</div>
</template>
<script setup>
const openSignIn = () => {
try{
  const signInKey = document.querySelector('#signin_key');
      signInKey.click();
    }catch(error){
      console.error('Error break/get:', error);
      }
  }
</script>
 
<style scoped>
 
.start-link {
  text-decoration: underline;
  cursor: pointer;
  color: #86b7fe !important;
}
</style>