
<template>
  <div ref="modalRef" class="modal fade" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header color-black">
          <h5 class="modal-title">{{ forgotPasswordMode ? "Forgot Password" : "Sign In" }}</h5>
          <button type="button" class="border-0 bg-transparent" @click="closeDialog" aria-label="Close">
            <i class="custom-icon close-icon icon-lg"></i>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="forgotPasswordMode" class="card-body">
            <!-- Forgot Password Section -->
           <form @submit.prevent="sendForgotPasswordEmail">
            <div class="mb-3">
              <input
                class="form-control"
                v-model="forgotPasswordEmail"
                id="forgotPasswordEmail"
                type="email"
                placeholder="Enter your email to reset password"
              />
            </div>
            <div class="text-center">
              <button class="btn btn-success w-100 mb-2" type="submit">
                Send Reset Link
              </button>
              <button class="btn btn-secondary w-100" type="button" @click="toggleToSignIn">
                Back to Sign In
              </button>
            </div>
          </form>

          </div>
          <div v-else class="card-body">
            <!-- Sign In Section -->
            <form @submit.prevent="signIn">
              <div class="mb-3">
                <input
                  class="form-control"
                  v-model="email"
                  id="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  :class="{ 'is-invalid': emailError }"
                  @blur="validateEmail"
                />
                <div v-if="emailError" class="invalid-feedback">{{ emailError }}</div>
              </div>
              <div class="mb-3">
                <input
                  class="form-control"
                  v-model="password"
                  id="password"
                  type="password"
                  placeholder="Password"
                  name="password"
                  :class="{ 'is-invalid': passwordError }"
                  @blur="validatePassword"
                />
                <div v-if="passwordError" class="invalid-feedback">{{ passwordError }}</div>
              </div>
              <div class="mb-3 text-end">
                <a @click="toggleForgotPassword" class="cursor-pointer text-success font-weight-bold">
                  Forgot Password?
                </a>
              </div>
              <div class="text-center">
                <button class="btn btn-dark w-100" type="submit">
                  Sign in
                </button>
              </div>
            </form>
          </div>
          <div v-if="flowError" class="invalid-feedback d-block">{{ flowError }}</div>
        
          <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a
                      @click="openSignUp"
                      class="cursor-pointer text-success text-gradient font-weight-bold"
                      >Sign up</a
                    >
                  </p>
                </div> 

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, shallowRef, nextTick} from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie';
// import { onBeforeUnmount, onBeforeMount } from "vue";
// import { useStore } from "vuex";
import { Modal } from "bootstrap"; 
const modalRef = shallowRef(null);
 let modal = null;
// const store = useStore(); 
// onBeforeMount(() => {
//   store.state.showSidenav = false;
// });
// onBeforeUnmount(() => {
//   store.state.showSidenav = true;
// });
const forgotPasswordMode = ref(false);  // New flag for forgot password mode
const forgotPasswordEmail = ref('');
const email = ref('');
const flowError = ref('');
const password = ref('');
const rememberMe = ref(false);
const emailError = ref('');
const passwordError = ref('');

const emit = defineEmits(["update:emitSignIn"]);
const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.value) {
    emailError.value = 'Email is required.';
  } else if (!emailPattern.test(email.value)) {
    emailError.value = 'Please enter a valid email address.';
  } else {
    emailError.value = '';
  }
};

const validatePassword = () => {
  if (!password.value) {
    passwordError.value = 'Password is required.';
  } else if (password.value.length < 5) {
    passwordError.value = 'Password must be at least 5 characters long.';
  } else {
    passwordError.value = '';
  }
};
const signIn = async () => {
  validateEmail();
  validatePassword();
  if(password.value !== "" &&  email.value !== ""){
  try {
    flowError.value = '';
    // console.log("process.env.BASE_URL", process.env.VUE_APP_BASE_URL);
    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/auth/login`, {
      "password": password.value,
      "email": email.value
    });
    
    console.log('Response:', response);

    setTimeout(() => {
      console.log("response.data", response.data);
      Cookies.set('name', response.data.name, { expires: 7 });
      Cookies.set('id', response.data.id, { expires: 7 });
      Cookies.set('access_token', response.data.access_token, { expires: 1/3 }); // Set token for 8 hours
      Cookies.set('refresh_token', response.data.refresh_token, { expires: 7 });  // Keep refresh token for 7 days
      if (rememberMe.value) {
        Cookies.set('rememberMe', 'true', { expires: 7 });
      } else {
        Cookies.remove('rememberMe');
      }
      location.reload(true);
    }, 100);
  } catch (error) {
    flowError.value = error.response?.data?.message;
    console.log('Error:', error);    
  }
}
};

    const toggleForgotPassword = () => {
      forgotPasswordMode.value = !forgotPasswordMode.value;
      forgotPasswordEmail.value = '';
    }; 

    const toggleToSignIn = () => {
      forgotPasswordMode.value = false;
    };

  const sendForgotPasswordEmail = async () => {
  try {
    await axios.post(`${process.env.VUE_APP_BASE_URL}/user/forgot-password`, { email: forgotPasswordEmail.value });
    forgotPasswordMode.value = false; 
    closeDialog();
    alert("Password reset link has been sent to your email.");
  } catch (error) {
    alert("Error: " + error.response.data.message);
  }
};

// Check cookies on mounted
  onMounted(() => {
      modal = new Modal(modalRef.value, {
        backdrop: 'static',
      });
         modal.show('static');
            console.log("show sign in modal",modal);
            //  modal.show('static');
    if (Cookies.get('rememberMe') === 'true') {
      email.value = Cookies.get('email') || '';
      password.value = Cookies.get('password') || '';
      rememberMe.value = true;
    }
  });

  const openSignUp = async () => { 
    closeDialog();
    await nextTick();
    await Open();
  };

  const Open = async () => {
  const signUpKey = document.querySelector('#signUp_key');
      signUpKey.click();
    }

  const closeDialog = () => { 
        modal.hide();
        emit('update:emitSignIn');
    };
        
  
</script> 