
<template>
    <div ref="modalRef" class="modal fade" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
        <div class="modal-header color-black">
            <h5 class="modal-title">
                Edit Break/Add Break manually
            </h5>
            <button type="button" class="border-0 bg-transparent" @click="closeDialog()" aria-label="Close"><i class="custom-icon close-icon icon-lg"></i></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="mb-3 col-md-5">
                    <label for="startTime" class="form-label">Start Time</label>
                    <input type="time" class="form-control handle-focus" @change="updateDuration" v-model="breaks.startTime" required
                    @blur="validateTimes">
                    <div v-if="errors.startTime" class="d-block invalid-feedback">{{ errors.startTime }}</div>
                </div>
                <div class="mb-3 col-md-5">
                    <label for="endTime" class="form-label">End Time</label>
                    <input type="time" class="form-control handle-focus" @change="updateDuration" v-model="breaks.endTime" required
                    @blur="validateTimes">
                    <div v-if="errors.endTime" class="d-block invalid-feedback">{{ errors.endTime }}</div>
                </div>
                <div class="mb-3 col-md-2">
                    <label for="duration" class="form-label">Duration (minutes)</label>
                    <input type="text" class="form-control" disabled="true" v-model="breaks.duration" required>
                </div>
            </div> 
            <div class="row">
                <div class="mb-3 col-md-6"> 
                    <label for="createdDate" class="form-label">Created Date</label>
                    <input type="date" class="form-control handle-focus" v-model="breaks.createdDate" required>
                    <div v-if="errors.createdDate" class="d-block invalid-feedback">{{ errors.createdDate }}</div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="type" class="form-label">Break Type</label> 
                    <select v-model="breaks.type" class="form-select handle-focus form-control-alternative mb-3" required>
                        <option v-for="brk in breakTypes" :key="brk" :value="brk">{{ brk }}</option>
                    </select>
                    <div v-if="errors.type" class="d-block invalid-feedback">{{ errors.type }}</div>
                </div>
            </div> 
            <div class="mb-3">
                <label for="reason" class="form-label">Reason</label>
                <textarea class="form-control handle-focus" v-model="breaks.reason" rows="3" maxlength="2000" required></textarea>
            </div> 
        </div>
        <div class="modal-footer bg-gradient">
            <button type="button" class="btn btn-sm bg-gradient-success float-start" @click="updateRecord">
                Save
            </button>    
        </div>
        </div>
    </div>
    </div>
</template>
<script setup>
    import { shallowRef, ref, onMounted } from "vue";
    import apiClient from '../../../api/apiClient';
    import Cookies from 'js-cookie'; 
    import { getTimeDifferenceInMinutes, formatDateToDatetimeLocal } from '../../utilities/transforms';
    import { Modal } from "bootstrap";

    const userId = Cookies.get('id');
    const modalRef = shallowRef(null);
    const emit = defineEmits(["update:emitEditBreaks", "update:archivesEditBreaks"]);
    let modal = null; 
    let breaksLoading = ref(false);
let breakTypes = ref(null);
    const breaks = ref({
        startTime: '',
        endTime: '',
        duration: null,
        createdDate: null,
        reason: '',
        type: '',
        userId: ''
    });
    const errors = ref({
        startTime: '',
        endTime: '',
        type: '',
        createdDate: ''
    });
  
    const props = defineProps({
        editRecord : { type : Object, required:false },
        isArchives : { type : Boolean, required:false }
    });  
     const updateDuration = () => {
      if (breaks.value.startTime && breaks.value.endTime) {
        const minutes = getTimeDifferenceInMinutes(breaks.value.startTime, breaks.value.endTime);
        breaks.value.duration = minutes;
        }  
    };

    const getBreakTypes = async () => {
    try {  
          breaksLoading.value = true;
          const response = await apiClient.get('/type/get', {
          params: {
            userId: userId
          }
        });
        breakTypes.value = response.data[0].type;   
        } catch (error) {
          console.error('Error break/get:', error);
        } 
        finally {
          breaksLoading.value = false;
        }
    }
    
    onMounted( async() => {
        modal = new Modal(modalRef.value);
        console.log("show edit modal",modal);
        modal.show('static'); 
        await getBreakTypes();

      if(props.editRecord) {
        breaks.value = props.editRecord;
        // breaks.value.startTime = (props.editRecord.startTime);
        // breaks.value.endTime = (props.editRecord.endTime);
        breaks.value.createdDate = formatDateToDatetimeLocal(props.editRecord.createdDate);
    } else {
        breaks.value = {};
        breaks.value.startTime = "";
        breaks.value.endTime ="";
        breaks.value.createdDate =null;
        breaks.value.duration =null;
        breaks.value.reason = "";
        breaks.value.type = breakTypes.value[0];
        breaks.value.userId = userId;
    }
    });

    const closeDialog = () => { 
         emit('update:emitEditBreaks', false);
        modal.hide();
    };

        const validateTimes = () => {
        errors.value.startTime = '';
        errors.value.endTime = '';
        if (!breaks.value.startTime) {
            errors.value.startTime = 'Start time is required.';
        }
        if (!breaks.value.endTime) {
            errors.value.endTime = 'End time is required.';
        }
        if (breaks.value.startTime && breaks.value.endTime) {
            const start = new Date(`1920-01-01T${breaks.value.startTime}:00`);
            const end = new Date(`1920-01-01T${breaks.value.endTime}:00`);
            if (start >= end) {
                errors.value.endTime = 'End time must be after start time.';
            }
        }

        return !errors.value.startTime && !errors.value.endTime;
    };

    const validateType = () => {
    try{
        if (!breaks.value.type) {
            errors.value.type = 'Name is required.';
        } else {
            errors.value.type = '';
        }
        return !errors.value.type;
        }catch(error){
      console.error('Error break/get:', error);
      }
    };

    const validateCreatedDate = () => {
    try{
    const date = new Date(breaks.value.createdDate);
    if (!breaks.value.createdDate) {
        errors.value.createdDate = 'Created Date is required.';
    } else if (isNaN(date.getTime())) {
        errors.value.createdDate = 'Invalid date format.';
    } else {
        errors.value.createdDate = '';
    }
    return !errors.value.createdDate;
    }catch(error){
      console.error('Error break/get:', error);
      }
};

     const updateRecord = async () => {
     try{
          const editId = breaks.value._id;
          if (validateTimes() && validateType() && validateCreatedDate()) {
            breaks.value.reason = breaks.value.reason?breaks.value.reason:'';
            try {
            console.log("props.isArchives",props.isArchives);
            delete breaks.value._id;
            console.log("breaks.value._id",breaks.value);
            if(editId) {
                const response = await apiClient.put(`/break/edit/${editId}`, breaks.value);
                console.log('edit Response:', response);
            } else {
                const response = await apiClient.post('break/add', breaks.value);
                console.log('add Response:', response);
            }
            } catch (error) {
                console.error('Error:', error);
            }
            finally{ 
                console.error('finally:');
                
                if(props.isArchives) {
                emit('update:archivesEditBreaks', true);
                } else {
                    emit('update:emitEditBreaks', true);
                }
                modal.hide();
            }
        }
        }catch(error){
      console.error('Error break/get:', error);
      }
    }
</script>