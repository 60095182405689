<template>
  <main>
    <div class="container-fluid">
      <div
        class="page-header min-height-200">
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      
      <div v-if="info" class="card shadow-lg mt-n8">
        <div class="card-body p-3">
          <div class="row gx-4"> 
              <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1 text-uppercase">{{ info?.name }}</h5>
                <p class="mb-0 font-weight-bold text-sm">Take a look at your work hours and break times</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="row mt-5">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="card"> 
            <div class="card-body">
          
              <div v-if="info" class="mt-4">

                <div class="pb-0 card-header bg-primary border-radius-xs border-radius-md border-radius-lg">
                  <div class="d-flex justify-content-between">
                  <h6 class="pb-1">Breaks </h6>
                   <div class="d-flex justify-content-between align-items-center pb-2 rounded bg-primary text-white">
 
                    <span class="font-weight-bold">Total: {{ filteredBreaks.totalCount }}</span>
                  </div></div></div>
                    <div class="card-body">
                      <div class="row">
                         <div v-for="(value, key) in filteredBreaks.filtered" :key="key" class="mb-2">
                          <div class="bg-secondary text-white d-flex justify-content-between align-items-center p-2 rounded">
                            <span>{{ key }}</span>
                            <span>{{ value }}</span>
                          </div>
                        </div>
                        </div> 
                    </div> 

                   <div class="pb-0 card-header bg-primary border-radius-xs border-radius-md border-radius-lg">
                  <div class="d-flex justify-content-between">
                  <h6 class="pb-1">Tasks </h6>
                   <div class="d-flex justify-content-between align-items-center pb-2 rounded bg-primary text-white">
                    <span class="font-weight-bold">Total: {{ filteredTasks.totalCount }}</span>
                  </div></div></div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5 mb-3 d-flex" v-for="(value, key) in filteredTasks.filtered" :key="key">
                          <div :class="getTaskBadgeClass(key)" class="p-2 rounded">
                            <strong>{{ key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()) }}:</strong> {{ value }}
                          </div>
                        </div>
                      </div>
                    </div>
 
                    
                  </div>
                </div> 
          </div>
        </div>
        <div v-if="info && info.email" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <profile-card :userId="userId" :name="info.name" :email="info.email" />
        </div>
        <div>
           <button type='button' @click="deleteAccount" class='btn btn-danger d-flex ms-auto'>Delete</button>
        </div>
      </div>
      
  </main>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ProfileCard from "./components/ProfileCard.vue";
import apiClient from '../api/apiClient';
import Cookies from 'js-cookie'; 
const userId = Cookies.get('id');
// const body = document.getElementsByTagName("body")[0];
const info = ref(null);
const store = useStore();
// let breakCounts = ref(0);
// let taskCounts = ref(0);
const token = computed(() => {
      return Cookies.get('access_token');;
  });
const getInfo = async () => {
    try {  
            const response = await apiClient.get('/settings/info', {
                params: { userId }
            });
            info.value = response.data;
            console.log('User Info:', response.data);    
          } catch (error) {
            console.error('getInfo:', error);
          } 
    }

    const filteredTasks = computed(() => {
      const { totalCount, ...filtered } = info.value.taskCounts;
      return {totalCount, filtered};
    });

     const filteredBreaks = computed(() => {
       const { totalCount, ...filtered } = info.value.breakTypeCounts; 
      return {totalCount, filtered};
    });

    const getTaskBadgeClass = (key) => {
      switch (key) {
        case "normal":
          return "bg-secondary text-white d-flex";
        case "high":
          return "bg-warning text-dark d-flex";
        case "critical":
          return "bg-danger text-white d-flex";
        case "urgent":
          return "bg-danger text-white d-flex";
        case "important":
          return "bg-info text-white d-flex";
        case "inProgress":
          return "bg-primary text-white d-flex";
        case "toDo":
          return "bg-dark text-white d-flex";
        case "completed":
          return "bg-success text-white d-flex";
        default:
          return "bg-light text-dark d-flex";
      }
    };

onMounted( async() => {
  if(!token.value) {
    window.location.href = '/';
  }
  store.state.isAbsolute = true;
  setNavPills();
  setTooltip();
  await getInfo();
});

const deleteAccount = async () => {
  Swal.fire({
    title: 'Are you sure?',
    html: "Do you really want to delete your account?<br> <span class='text-danger font-weight-bolder'>NOTE:</span> This action will also <strong>DELETE</strong> your profile permanently.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  })
};

</script>

<style scoped>
  .page-header {
    background-image: '';
    margin-right: -24px;
    margin-left: -34%;
  }
  .card-header:first-child {
    border-radius: 1rem;
  }
</style>