<template>
    <div class="card"> 
            <div class="card-body"> 
    <div class="row justify-content-center">
      <div class="col-4 col-lg-4 order-lg-2">
        <div class="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
        </div>
      </div>
    </div>
    <div class="card-header text-center border-0 pt-0 pt-lg-2 pb-4 pb-lg-2">
      <div class="mt-4">
        <h2 class="mb-4">User Profile</h2>

        <!-- Name Section -->
        <div class="mb-3 d-flex align-items-center">
          <label for="name" class="form-label me-2 pt-2">Name:</label>
          <p v-if="!isEditingName" class="mb-0 me-2">{{ name }}</p>
          <input v-if="isEditingName" v-model="name" type="text" class="form-control me-2" />
          <span v-if="!isEditingName" @click="toggleEdit('name')">
            <i class="cursor-pointer custom-icon edit-icon"></i>
          </span>
          <div v-if="isEditingName" class="d-flex mt-3">
          <button @click="updateName" class="btn btn-sm btn-primary me-2">Update</button>
          <button @click="cancelEdit('name')" class="btn btn-sm btn-secondary">Cancel</button>
          
        </div>
        </div>

        <!-- Email Section -->
       <div class="mb-3 d-flex align-items-center">
        <label for="email" class="form-label me-2 pt-2">Email:</label>
        <p v-if="!isEditingEmail" class="mb-0 me-2">{{ email }}</p>
        <input v-if="isEditingEmail" v-model="email" type="email" class="form-control me-2" />
        
        <!-- Edit Icon -->
        <span v-if="!isEditingEmail" @click="toggleEdit('email')">
          <i class="cursor-pointer custom-icon edit-icon"></i>
        </span>
        
        <!-- Update and Cancel Buttons -->
        <div v-if="isEditingEmail" class="d-flex mt-3">
          <button @click="updateEmail" class="btn btn-sm btn-primary me-2">Update</button>
          <button @click="cancelEdit('email')" class="btn btn-sm btn-secondary">Cancel</button>
        </div>
      </div>



        <!-- Old Password -->
        <div class="mb-3 d-flex align-items-center">
          <label v-if="!isEditingPassword" for="oldPassword" class="form-label me-2">Current Password:</label>
          <p v-if="!isEditingPassword" class="mb-0 me-2">********</p>
          <span v-if="!isEditingPassword" @click="toggleEdit('password')">
            <i class="cursor-pointer custom-icon edit-icon"></i>
          </span>
        </div>

        <div v-if="isEditingPassword">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
          <label for="newPassword" class="form-label">New Password</label>
          <input v-model="newPassword" type="password" class="form-control" placeholder="Enter new password" />
        </div>

        <!-- Confirm Password -->
        <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
          <label for="confirmPassword" class="form-label">Confirm New Password</label>
          <input v-model="confirmPassword" type="password" class="form-control" placeholder="Confirm new password" />
        </div>
      </div> 
        <button @click="updatePassword" class="btn btn-sm btn-primary">Update</button>
        <button @click="cancelEdit('password')" class="ms-3 btn btn-sm btn-secondary">Cancel</button>
        </div> 
     </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'; 
import apiClient from '../../api/apiClient';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

const props = defineProps({
  userId: {
    type: String,
    required: true
  }, 
  name: {
    type: String,
    required: true
  }, 
  email: {type: String, required: true}
});
// const oldPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const isEditingName = ref(false);
const isEditingEmail = ref(false);
const isEditingPassword = ref(false); 
let oldEmail = ref(props.email);
let oldName= ref(props.name);
const name = ref(props.name);
const email = ref(props.email);
const toggleEdit = (field) => {
  if (field === 'name') {
    isEditingName.value = !isEditingName.value;
  } else if (field === 'email') {
    isEditingEmail.value = !isEditingEmail.value;
  } else if (field === 'password') {
    isEditingPassword.value = !isEditingPassword.value;
  }
};


const cancelEdit = (field) =>{
  if (field === 'name') {
    isEditingName.value = !isEditingName.value;
  } else if (field === 'email') {
    isEditingEmail.value = !isEditingEmail.value;
  } else if (field === 'password') {
    isEditingPassword.value = !isEditingPassword.value;
  }
}

const updateEmail = async () => {
  if (email.value === oldEmail.value) {
    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'The old email is the same as the new email!',
    });
    return;
  }

  try {
    const userId = props.userId;
    const response = await apiClient.put(`/user/update-email/${userId}`, {
      email: email.value,
    });
    console.log("response response", response);
    if (response.data?.status === 'success') {
      isEditingEmail.value = !isEditingEmail.value;
      oldEmail.value = email.value;
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: response.message,
      });
    
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Error updating email.',
      });
    }
  } catch (error) {
    let errorMessage = "An unexpected error occurred while updating the Email.";
    if(error.response?.data?.message){
        errorMessage = error.response.data.message;
    }
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: errorMessage,
    });
  }
};


const updateName = async () => {

  if (name.value === oldName.value) {
    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'The old name is the same as the new name!',
    });
    return;
  }

  try {
    const userId = props.userId;
    const response = await apiClient.put(`/user/update-name/${userId}`, {
      name: name.value,
    });
    console.log("response response", response);
    if (response.data?.status === 'success') {
      Cookies.set('name', name.value, { expires: 7 });
      window.location.reload(true);
      isEditingName.value = !isEditingName.value;
      oldName.value = name.value;
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: response.message,
      });
    
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Error updating name.',
      });
    }
  } catch (error) {
    let errorMessage = "An unexpected error occurred while updating the Name.";
    if(error.response?.data?.message){
        errorMessage = error.response.data.message;
    }
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: errorMessage,
    });
  }  
};

  const updatePassword = async () => {
    if(newPassword.value !== confirmPassword.value){
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'The old password is the same as the new password!',
        });
        return;
      }
      try {
        const userId = props.userId;
        const response = await apiClient.put(`/user/update-password/${userId}`, {
          password: newPassword.value,
       });
       console.log(response);
       if (response.data?.status === 'success') {
      isEditingPassword.value = !isEditingPassword.value;
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: response.message,
      });
    
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Error updating name.',
      });
    }
      } catch (error) {
    let errorMessage = "An unexpected error occurred while updating the Password.";
    if(error.response?.data?.message){
        errorMessage = error.response.data.message;
    }
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: errorMessage,
    });
  }  
  };
  

</script> 