import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tasks from "../views/Tasks.vue";
import Archives from "../views/Archives.vue"; 
import Settings from "../views/Settings.vue";
import privacyPolicy from "../views/components/privacy-policy/privacyPolicy.vue";
import deleteAccount from "../views/components/delete-account/deleteAccount.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
// import contactUs from "../views/components/common/conatct-us.vue";
// import axios from 'axios';
import Cookies from 'js-cookie';
import leaves from "../views/components/leaves/leaves.vue";

const handleSocialAuthCallback = async (to, from, next) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);

    const accessToken = urlParams.get('access_token');
    const refreshToken = urlParams.get('refresh_token');
    const name = urlParams.get('name');
    const id = urlParams.get('id');

    if (accessToken && refreshToken) {
      Cookies.set('access_token', accessToken, { expires: 7 });
      Cookies.set('refresh_token', refreshToken, { expires: 7 });
      Cookies.set('name', name, { expires: 7 });
      Cookies.set('id', id, { expires: 7 }); 
      window.history.replaceState({}, document.title, window.location.pathname); 
      location.reload();  
    } else {
      console.error('Missing tokens or user information');
      next('/login'); // Redirect to login if tokens are missing
    }
  } catch (error) {
    console.error('Error handling Google callback:', error);
    next('/login'); // Redirect to login on error
  }
}; 

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/breaks",
  },
  { 
   path: '/auth/callback',
    component: Dashboard,  // Use the correct component
    beforeEnter: handleSocialAuthCallback 
  },{
    path: "/breaks",
    name: "Breaks",
    component: Dashboard,
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: Tasks,
  },
  {
    path: "/archives",
    name: "Archives",
    component: Archives,
  },
  {
    path: "/leaves",
    name: "Leaves",
    component: leaves,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: privacyPolicy,
  },
  {
    path: "/delete-account",
    name: "Delete Account",
    component: deleteAccount,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  // Catch-all route for unknown URLs
  {
    path: "/:pathMatch(.*)*", 
    redirect: "/" 
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});



export default router;
