<template>
   <footer class="footer">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-lg-between">
          <div class="mb-4 col-6 mb-lg-0">
            <div class="text-sm text-center copyright text-lg-start text-muted">
          © {{ new Date().getFullYear() }} <spn class="text-success">Work Break</spn>
        </div>
          </div>
          <div class="col-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a @click="openContactUs"
                  class="nav-link cursor-pointer link-primary"
                  >Contact Us</a
                >
              </li>
              <li class="nav-item">
                <a @click="openContactUs"
                  class="nav-link cursor-pointer link-primary"
                  >About Us</a
                >
              </li>
              <li class="nav-item">
                <a href="/privacy-policy" 
                  class="nav-link cursor-pointer link-primary"
                  >Privacy Policy</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <contactUs v-if="isContactUs" @update:emitCloseContactUs="closeContactUs"></contactUs>
    </footer>
</template>
<script setup>  
import { ref, nextTick } from 'vue';
import contactUs from '../views/components/common/conatct-us.vue';
let isContactUs = ref(false);

const openContactUs = () => {
  isContactUs.value = true;
}
 
const closeContactUs = async () => {
  await nextTick();
  isContactUs.value = false;
}

</script>
