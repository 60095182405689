import apiClient from '../../../api/apiClient';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

const userId = Cookies.get('id');
export const deleteRecord = async (id, type) => {
  try {
    // Show the confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });

    if (result.isConfirmed) {
      // Proceed with deletion if confirmed
      let url;
      if (type === 'break') {
        url = `break/delete/${id}`;
      } else if (type === 'task') {
        url = `task/delete/${id}`;
      } else if (type === 'leaves') {
        url = `leaves/delete/${id}`;
      }
      const response = await apiClient.delete(url, {
        params: {
          userId,
        },
      });

      // Show success message
      Swal.fire('Deleted!', 'Your record has been deleted.', 'success');
      return response.data;
    } else {
      // User cancelled the deletion
      Swal.fire('Cancelled', 'Your record is safe.', 'error');
      return null; // Or handle as needed
    }
  } catch (error) {
    console.error('Error in delete record:', error);
    // Show error message
    Swal.fire('Error!', 'There was an error deleting your record.', 'error');
  } finally {
    console.log('finally completed');
  }
};
