<template>
  <div class="card z-index-2 p-3">
    <div class="row mb-2">
      <span class='font-weight-bold col-md-2 pt-2'>Select Time Period</span> 
 
    <select  class='col-md-6 col-sm-6 form-select form-control-alternative w-15' v-model="selectedTime" @change="updateDays">
    <option v-for="period in standardSlots" :key="period.value" :value="period.value">
      {{ period.label }}
    </option>
    <optgroup v-if="biWeekSlot" :label="biWeekSlot.label">
      <option v-for="week in biWeekSlot.biWeek" :key="week" :value="week.value">
        {{ week.label }}
      </option>
    </optgroup>
    </select>
    
    <div v-if="slotBreakList.length === 0" class="text-end col-md-8 text-end mt-2  font-weight-bolder">
      There is no break for <span class="uptext-uppercase link-primary"> {{ selectedTime }}</span></div>
    </div> 
      <div v-if="slotBreakList?.length>0"> 
      <canvas class="pb-2" id="myLineChart" width="100" height="40"></canvas>
       
      <hr>

      <div class="pb-0 card-header border-radius-xs border-radius-md border-radius-lg">
          <div class="d-flex justify-content-between">
            <h6 class="pb-1">Breaks Categories</h6>
          </div>
        </div>
        </div>
        <div v-else class="card z-index-2 p-3">
            <carousel
      :images="[
        { src: '/carousel/bv1.png', alt: 'break view' },
        { src: '/carousel/bv2.png', alt: 'break view' },
        { src: '/carousel/bv3.png', alt: 'break view' }
      ]"
      id="carousel-bv"
    /></div>

      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0" v-if="slotBreakList.length>0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Break Type
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Reasons
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Created On
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Duration(minutes)
              </th>
               <th
                class="text-uppercase text-end text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in slotBreakList" :key="index">
              <td>
                <div class="d-flex px-2">
                  <div class="my-auto">
                    <h6 class="mb-0 font-weight-bold text-sm handle-large-text" :title="value.type">{{value.type}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0 handle-large-text" :title="value.reason">{{ value.reason }}</p>
              </td>
               <td>
                <p class="text-sm font-weight-bold mb-0 handle-large-text" :title="value.reason">{{ convertDate(value.createdDate) }}</p>
              </td>
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ value.duration }}</span>
              </td> 
              <td class="text-end">
                <span class="ms-2 mouse-pointer" @click="viewBreak(value)"><i class="custom-icon view-icon"></i></span>
                <span class="ms-2 mouse-pointer" @click="editBreak(value)"><i class="custom-icon edit-icon"></i></span>
                <span class="ms-2 mouse-pointer" @click="deleteBreak(value._id)"><i class="custom-icon trash-icon"></i></span>
              </td>
            </tr>
          </tbody>
        </table>

         <div v-if="token && slotBreakList.length===0">
          <breakInfo2></breakInfo2>
        </div>
        <div v-if="!token">
          <breakInfo3></breakInfo3>
        </div> 
      </div>
      <div v-if="slotBreakList?.length===0" class="m-1 row">
         <a @click="addBreakManually" class="col-3 text-start text-success mt-2 start-link">Add Break Manually</a>
         <a @click="gotoTop" class="col-9 text-end text-success mt-2 start-link">Start Break</a>
      </div> 
  </div>
  
<EditBreak v-if="showEdit" :edit-record="editRecord" @update:emitEditBreaks="closeEdit"></EditBreak>
<ViewBreak v-if="showViewModal" :break-details="selectedBreak" @close="showViewModal = false"></ViewBreak>
</template>

<script setup>
import { onMounted, shallowRef, watch, ref, computed, nextTick} from 'vue';
const emit = defineEmits(["update:emitTodayBreaks"]);
import Chart from "chart.js/auto";
import { getDurations, convertMinutesToHoursAndMinutes, trimLabels, convertDate } from '../../utilities/transforms';
import EditBreak from './EditBreak.vue';
import ViewBreak from './ViewBreak.vue';
import carousel from '../common/carousel.vue';
import breakInfo2 from "../common/break-info2.vue";
import breakInfo3 from "../common/break-info3.vue";
import Cookies from 'js-cookie';
import { deleteRecord } from '../composables/deleteRecords';
let showViewModal = ref(false);
const selectedBreak = ref(null);
let showEdit = ref(false);
let editRecord = ref(null);
let breakTime = ref(null); 
let lineChart = shallowRef(null);
const standardSlots = computed(() => {
  return timePeriods.value.filter(slot => !slot.biWeek);
});

const token = computed(() => {
      return Cookies.get('access_token');;
  });

const biWeekSlot = computed(() => {
  return timePeriods.value.find(slot => slot.biWeek);
});
// const slots = ref(["Today","One Week","Two Week","Three Week","Four Week"]); 
const timePeriods = ref([
  { label: "Today", value: "Today" },
  { label: "Four Week", value: "Four Week" },
  {
    label: "Bi Week",
    biWeek: [
      { label: "Past Week", value: "Past Week"},
      { label: "Week Before Last", value: "Week Before Last"},
      { label: "Three Weeks Ago", value: "Three Weeks Ago"},
      { label: "Four Weeks Ago", value: "Four Weeks Ago"}
    ]
  }
]);

let selectedTime = ref('Today');
let slotBreakList = shallowRef([]);
const props = defineProps({
  breaksList : { type : Object, required:true },
})
 
  const viewBreak = async (task) => {
  try{
     showViewModal.value = false;
    await nextTick(); 
    selectedBreak.value = task;
    showViewModal.value = true;
    }catch(error){
      console.error('Error break/get:', error);
      }
  };

  const deleteBreak = async (id) => {
    const repsonseId = await deleteRecord(id, "break");
    console.log("delete response", repsonseId);
   if(repsonseId) { 
          emit('update:emitTodayBreaks');
        }
  }
  const renderChart = async() => {
    if(slotBreakList.value.length<1){
      return;
    }
    const durations = await getDurations(slotBreakList.value);  
    const totalMinutes = await Object.values(durations).reduce((sum, minutes) => sum + minutes, 0);

    breakTime.value =  convertMinutesToHoursAndMinutes(totalMinutes);
    const ctx = document.getElementById('myLineChart').getContext('2d');
    console.log("render chart", ctx);
    const breakTypeList = Object.keys(durations);
    const trimmedBreakTypeList = trimLabels(breakTypeList, 20);
    lineChart.value = new Chart(ctx, {
      type: 'line',
      data: {
        labels: trimmedBreakTypeList,
        datasets: [{
          label: `${selectedTime.value} break time is ${breakTime.value}`, // 'Duration',
          data: Object.values(durations),
          fill: true,
          borderColor: 'rgba(75, 192, 192, 1)',
          tension: 0.2,
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false, // don't display
            text: 'Today Breaks'
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Minutes',
              color: "#000",
                font: {
                  size: 16,
                  family: "Open Sans",
                  style: "bold",
                  lineHeight: 1,
                },
            },
            ticks: {
              callback: function(value) {
                return value; 
              }
            }
          },
          x: {
            title: {
              display: true,
              text: 'Break Type'
            }
          }
        }
      },
    });
  }

  const getDayWiseData = (isMounted) => {
          if(!props.breaksList){
            return
          }
          
          if (isMounted && selectedTime.value === "Today" && ((!props.breaksList.todayData || props.breaksList.todayData.length === 0) && props.breaksList.monthlyData.length>0)) {
            // If today's data is empty, fall back to four weeks' data 
            selectedTime.value = "Four Week";
            slotBreakList.value = props.breaksList.monthlyData;
          } else {
          switch (selectedTime.value) {
          case "Today":
            slotBreakList.value = props.breaksList.todayData;
            break;
          case "Past Week":
            slotBreakList.value = props.breaksList.oneWeekData;
            break;
          case "Week Before Last":
            slotBreakList.value = props.breaksList.twoWeekData;
            break;
          case "Three Weeks Ago":
            slotBreakList.value = props.breaksList.threeWeekData;
            break;
          case "Four Weeks Ago":
            slotBreakList.value = props.breaksList.fourWeekData;
            break;
          default:
            slotBreakList.value = props.breaksList.monthlyData;
            break;
        } 
      }
      }

      const updateDays = async() => {
        getDayWiseData(); 
        if(lineChart.value) {
          lineChart.value.destroy(); 
        }
          await renderChart();
        
      }
      watch(() => props.breaksList, async() => {
        selectedTime.value = "Today";
        getDayWiseData(true);
         if(lineChart.value) {
          lineChart.value.destroy(); 
         }
          await renderChart();
        
      });

      onMounted( async() => {
        getDayWiseData(true);
        await renderChart();
      }); 

      const closeEdit = async (isUpdated) => {
            console.log("isUpdated",isUpdated);
        if(isUpdated) { 
          emit('update:emitTodayBreaks');
        }
        showEdit.value = false;
      }

  const gotoTop = async() => {
      window.scrollTo(0, 0);
  }
  
  const addBreakManually = async() => {
      const signInKey = document.querySelector('#addBreakManually');
      signInKey.click(); 
  } 
  const editBreak = (value) => {
    // shallow copy of the value
    editRecord.value = {...value}
    showEdit.value = true;
  }
</script>

<style scoped>
.container {
  max-width: 900px;
  margin: auto;
}
textarea {
  resize: none;
}
.start-link {
  text-decoration: underline;
  cursor: pointer;
  color: #86b7fe !important;
}
.card-header {
  background-color: #2dce89;
}
hr {
  border: 1px solid #000; margin: 40px 0;
}
</style>
