<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/breaks"
          :class="getRoute() === 'breaks' ? 'active' : ''"
          navText="Dashboard"
        >
          <template v-slot:icon>
            <i class="custom-icon home-icon"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/tasks"
          :class="getRoute() === 'tasks' ? 'active' : ''"
          navText="Tasks"
        >
          <template v-slot:icon>
            <i class="custom-icon task-icon"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/archives"
          :class="getRoute() === 'Archives' ? 'active' : ''"
          navText="Archives"
        >
          <template v-slot:icon>
            <i class="custom-icon archives-icon"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/leaves"
          :class="getRoute() === 'Leaves' ? 'active' : ''"
          navText="Leaves"
        >
          <template v-slot:icon>
            <i class="custom-icon ban-icon"></i>
          </template>
        </sidenav-item>
      </li>      

      <li class="nav-item cursor-pointer">
       <a @click="openContactUs" class="nav-link"><div class="text-center d-flex align-items-center justify-content-center"><i class="custom-icon envelope-icon"></i></div><span class="nav-link-text ms-2">Contact Us</span></a>
      </li>
      <li class="mt-3 nav-item">
        <h6 
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2" 
        >
          MANAGMENT PAGES
        </h6>
      </li>

      <li v-if="token" class="nav-item">
        <sidenav-item
          to="/settings"
          class="profile"
          navText="Profile"
        >
          <template v-slot:icon>
            <i class="custom-icon profile-icon"></i>
          </template>
        </sidenav-item>
      </li>

      <!-- <li v-if="token" class="nav-item cursor-pointer">
       <a @click="signOut" class="nav-link"><div class="text-center d-flex align-items-center justify-content-center"><i class="custom-icon signout-icon"></i></div><span class="nav-link-text ms-2">Sign Out</span></a>
      </li> -->

      <li v-if="!token" class="nav-item cursor-pointer">
       <a @click="openSignIn" class="nav-link"><div class="text-center d-flex align-items-center justify-content-center"><i class="custom-icon profile-icon"></i></div><span class="nav-link-text ms-2">Sign in</span></a>
      </li>

      <li v-if="!token" class="nav-item cursor-pointer">
        <a @click="openSignUp" class="nav-link" id="signUp_key"><div class="icon-sm text-center d-flex align-items-center justify-content-center">
          <i class="custom-icon profile-icon"></i></div><span class="nav-link-text ms-2">Sign Up</span></a>
      </li>
    </ul>
  </div> 
  <Signin v-if="isSignIn" @update:emitSignIn="isSignIn = false"></Signin>
  <SignUp v-if="isSignUp" @update:emitSignUp="closeSignup"></SignUp>
  <conatctUs v-if="isContactUS" @update:emitCloseContactUs="closeContactUs"></conatctUs>
</template>
<script setup> 
import { useRoute } from "vue-router"; 
import { ref, computed, nextTick } from "vue"; 
import Cookies from 'js-cookie'; 
import SidenavItem from "./SidenavItem.vue";
import conatctUs from "../../views/components/common/conatct-us.vue"; 
import Signin from "../../views/Signin.vue";
import SignUp from "../../views/Signup.vue"; 
let isSignIn = ref(false);
let isSignUp = ref(false);
let isContactUS = ref(false);
const token = computed(() => {
  return Cookies.get('access_token');;
});

const closeSignup = async () => {
  await nextTick();
  isSignUp.value = false;
}

// async function signOut() { 
//     Cookies.remove('name');
//     Cookies.remove('id'); 
//     Cookies.remove('access_token'); 
//     location.reload(true);
// }


const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

const openSignIn = async () => {
  isSignUp.value = false;  
  isSignIn.value = true;
}

const openContactUs = async () => {  
  isContactUS.value = true;
}

const closeContactUs = async () => {
  await nextTick();
  isContactUS.value = false;
}

const openSignUp = async () => {
  isSignIn.value = false; 
  isSignUp.value = true;
}
</script>