<template>
 
   <div ref="modalRef" class="modal fade" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
        <div class="modal-header color-black">
                        <h5 class="modal-title">
                            Sign Up
                        </h5>
                        <button type="button" class="border-0 bg-transparent" @click="closeDialog()" aria-label="Close" ><i class="custom-icon close-icon icon-lg"></i></button>
                    </div>
        <div class="modal-body">
          <div class="z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Register with</h5>
            </div>
            <div class="row px-xl-5 px-sm-4 px-3">
                <div class="col-4 me-auto px-1">
                  <!-- // google -->
                <a class="btn btn-outline-light w-100"  @click="loginWithGoogle">
                  <svg
                    width="24px"
                    height="32px"
                    viewBox="0 0 64 64"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(3.000000, 2.000000)"
                        fill-rule="nonzero"
                      >
                        <path
                          d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                          fill="#4285F4"
                        />
                        <path
                          d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                          fill="#34A853"
                        />
                        <path
                          d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                          fill="#FBBC05"
                        />
                        <path
                          d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                          fill="#EB4335"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
              <div class="col-4 me-auto px-1">
                <a class="btn btn-outline-light w-100" @click="loginWithGithub">
                  <svg aria-hidden="true" class="octicon octicon-mark-github" height="32" version="1.1" viewBox="0 0 16 16" width="24"><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path>
</svg>
                </a>
              </div>
              <div class="col-4 ms-auto px-1 disabled">
                <a class="btn btn-outline-light w-100" @click="loginWithFacebook">
                  <svg
                    width="24px"
                    height="32px"
                    viewBox="0 0 64 64"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(3.000000, 3.000000)"
                        fill-rule="nonzero"
                      >
                        <circle
                          fill="#3C5A9A"
                          cx="29.5091719"
                          cy="29.4927506"
                          r="29.4882047"
                        />
                        <path
                          d="M39.0974944,9.05587273 L32.5651312,9.05587273 C28.6886088,9.05587273 24.3768224,10.6862851 24.3768224,16.3054653 C24.395747,18.2634019 24.3768224,20.1385313 24.3768224,22.2488655 L19.8922122,22.2488655 L19.8922122,29.3852113 L24.5156022,29.3852113 L24.5156022,49.9295284 L33.0113092,49.9295284 L33.0113092,29.2496356 L38.6187742,29.2496356 L39.1261316,22.2288395 L32.8649196,22.2288395 C32.8649196,22.2288395 32.8789377,19.1056932 32.8649196,18.1987181 C32.8649196,15.9781412 35.1755132,16.1053059 35.3144932,16.1053059 C36.4140178,16.1053059 38.5518876,16.1085101 39.1006986,16.1053059 L39.1006986,9.05587273 L39.0974944,9.05587273 L39.0974944,9.05587273 Z"
                          fill="#FFFFFF"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
              </div> 
              <div class="mt-2 position-relative text-center">
                <p
                  class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                >
                  or
                </p>
              </div>
            </div>
            <div class="card-body">
             <form @submit.prevent="handleSignUp">
              <div class="mb-3">
                <input
                  id="name"
                  class="form-control"
                  v-model="name"
                  type="text"
                  placeholder="Name"
                  aria-label="Name"
                  @blur="validateName"
                  :class="{ 'is-invalid': nameError }"
                />
              <div v-if="nameError" class="invalid-feedback">{{ nameError }}</div>
              </div>
              
              <div class="mb-3">
                <input
                  id="email"
                  class="form-control"
                  v-model="email"
                  type="email"
                  maxlength="70"
                  placeholder="Email"
                  aria-label="Email"
                  @blur="validateEmail"
                  :class="{ 'is-invalid': emailError }"
                />
                <div v-if="emailError" class="invalid-feedback">{{ emailError }}</div>
              </div>
              
              <!-- <div class="mb-3">
                <select class="form-select form-control-alternative mb-3" v-model="role" required>
                  <option value="self">self</option>
                  <option value="company">Company</option>
                </select> 
              </div> -->

              <div class="mb-3">
                <input
                  class="form-control"
                  v-model="password"
                  id="password"
                  type="password"
                  placeholder="Password"
                  name="password" 
                  @blur="validatePassword"
                  :class="{ 'is-invalid': passwordError }"
                />
              <div v-if="passwordError" class="invalid-feedback">{{ passwordError }}</div>
              </div>

              <!-- <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" v-model="termsAccepted" @change="validateTermsAccepted" role="switch" id="flexSwitchCheckChecked" checked>
              <label class="pt-2 text-sm">
                I agree to the
                <a @click="openTerms" class="text-primary font-weight-bolder cursor-pointer">Terms and Conditions</a>
              </label>
            </div> -->
              <!-- <div v-if="termsError" class="d-block invalid-feedback">{{ termsError }}</div> -->
              <div class="text-center">
                <button
                  type="submit"
                  fullWidth
                  color="dark"
                  variant="gradient"
                  class="my-4 mb-2 btn btn-dark btn-sm w-100"
                >
                  Sign up
                </button>
              </div>
              <div v-if="flowError" class="invalid-feedback d-block">{{ flowError }}</div>
              
              <p class="text-sm mt-3 mb-0">
                Already have an account?
                <a @click="redirectToSignIn" class="cursor-pointer font-weight-bolder">Sign in</a>
              </p>
            </form> 
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>   
        <Terms v-if="isTerms"></Terms>
  <app-footer />

</template>

<script setup> 
import axios from 'axios';
import { ref, onMounted, nextTick} from "vue"; 
import { Modal } from "bootstrap";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Terms from './terms/Terms.vue';
const modalRef = ref(null);
 let modal = null;
let isTerms = ref(false);
const name = ref('');
const email = ref('');
const password = ref('');
const role = ref('self'); 
const flowError = ref("");
// const store = useStore(); 
// onBeforeMount(() => {
//     console.log("1111 store.state.showSidenav")
// });
// onBeforeUnmount(() => {
//     console.log("222 store.state.showSidenav")
// }); 
 const emit = defineEmits(["update:emitSignUp"]);
let nameError = ref('');
const emailError = ref('');
const passwordError = ref(''); 
 
const validateName = () => {
  if (!name.value) {
    nameError.value = 'Name is required';
  } else if (name.value.length < 3) {
    nameError.value = 'Name must be at least 3 characters long.';
  } else if (name.value.length > 60) {
    nameError.value = 'Name must not exceed 60 characters.';
  } else {
    nameError.value = '';
  }
};

const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.value) {
    emailError.value = 'Email is required.';
  } else if (!emailPattern.test(email.value)) {
    emailError.value = 'Please enter a valid email address.';
  } else {
    emailError.value = '';
  }
};

const validatePassword = () => {
  if (!password.value) {
    passwordError.value = 'Password is required.';
  } else if (password.value.length < 6) {
    passwordError.value = 'Password must be at least 6 characters long.';
  } else if (password.value.length > 20) {
    passwordError.value = 'Password must not exceed 20 characters.';
  } else {
    passwordError.value = '';
  }
};

 
// const toggleTermsAccepted = () => {
//   termsAccepted.value = !termsAccepted.value;
// };

const loginWithFacebook = async () => {   
  try {
      window.location.href = `https://nestjsapi-ptju.onrender.com/auth/facebook`;
      const response = await axios.get(`https://nestjsapi-ptju.onrender.com/auth/facebook`);
    console.log('Response from github:', response.data);
      
  setTimeout(() => {
    Cookies.set('name', response.data.name, { expires: 7 });
    Cookies.set('id', response.data.id, { expires: 7 });
    location.reload(true);
  }, 100);
  } catch (error) {
    
    console.error('Error:', error);
  }  
}

const loginWithGithub = async () => {   
 try {
    console.log('process.env.WORK_BREAK_UI:', process.env.WORK_BREAK_UI);
    console.log('process.env.VUE_APP_BASE_URL:', process.env.VUE_APP_BASE_URL);
    // window.location.href = `${process.env.WORK_BREAK_UI}/auth/github`;
    window.location.href = `https://nestjsapi-ptju.onrender.com/auth/github`;
    const url = `https://nestjsapi-ptju.onrender.com/auth/github`;
    console.log("url",url)
    const response = await axios.get(url);
    console.log('Response from github:', response.data);
      
  setTimeout(() => {
    Cookies.set('name', response.data.name, { expires: 7 });
    Cookies.set('id', response.data.id, { expires: 7 });
    location.reload(true);
  }, 100);
  } catch (error) {
    console.error('Error:', error);
  } 
  //  try {
  //       const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/auth/github`);
  //       console.log('Response from google:', response.data);
      
  // setTimeout(() => { 
  //     Cookies.set('name', response.data.name, { expires: 7 });
  //     Cookies.set('id', response.data.id, { expires: 7 });
  // }, 100);
  // } catch (error) {
  //       console.error('Error:', error);
  //     } 
}

const loginWithGoogle = async () => {    
   try {
    window.location.href = `https://nestjsapi-ptju.onrender.com/auth/google`;
    const response = await axios.get(`https://nestjsapi-ptju.onrender.com/auth/google`);
    console.log('Response from google:', response.data);
      
  setTimeout(() => {
    Cookies.set('name', response.data.name, { expires: 7 });
    Cookies.set('id', response.data.id, { expires: 7 });
    location.reload(true);
  }, 100);
  } catch (error) {
    console.error('Error:', error);
  } 
}

 onMounted(() => {
      modal = new Modal(modalRef.value, {
        backdrop: 'static',
      }); 
    modal.show('static');
  });
 

const handleSignUp = async () => {
      validateName();
      validateEmail();
      validatePassword(); 
      console.log('nameError nameError:', nameError.value);
      if (!nameError.value && !emailError.value && !passwordError.value ) {
  try {
      flowError.value = "";
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/user/signup`, {
          "name":name.value,
          "password":password.value,
          "email": email.value,
          "role": role.value        
        });
         closeDialog();
         Swal.fire('Verifaction email sent!', `Please verify your account. ${email.value}`, 'success');
        console.log('Response:', response);
      } catch (error) {
        flowError.value = error.response?.data?.message; 
        console.error('Error:', error);
      }  
      }
};

 const redirectToSignIn = async () => { 
    closeDialog();
    await nextTick();
    await Open();
  };

  const Open = async () => {
  const signInKey = document.querySelector('#signin_key');
      signInKey.click();
    }

 const closeDialog = () => {  
    modal.hide();
      emit('update:emitSignUp');
 };

</script>