<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12 mb-lg text-end">
          <button class="btn bg-gradient-info btn-md me-2 mb-3 w-20" @click="addTask">ADD TASK</button>
        </div>
      </div>  
        <div class="row mt-4">
          <DisplayTasks></DisplayTasks>
        </div>
      </div>
    </div>
    <AddTask v-if="showTask" @update:emitEditTask="updateAllTasks"></AddTask>
    <resetPassword v-if="showResetPassword"></resetPassword>
  </div>
</template>
<script setup> 
import { ref, nextTick, onMounted } from 'vue';
import { useStore } from "vuex";

import DisplayTasks from './components/tasks/DisplayTasks.vue';
import AddTask from "./components/tasks/AddTask.vue"; 
import resetPassword from "../views/reset-password.vue"; 
import { isAuth } from './components/composables/userAuth';
const store = useStore(); 
let showTask = ref(false);
let showResetPassword = ref(false);  
store.commit("isDashboard", false); 

const addTask = async() => {
  if(isAuth()) {
    showTask.value = false;
    await nextTick();  
    showTask.value = true;
  }
}

 const openPasswordModel = async () => { 
 

  showResetPassword.value = false;
    await nextTick();  
    showResetPassword.value = true;
 
      }

  onMounted( async () => {
  const queryParams = new URLSearchParams(window.location.search);
      const urlToken = queryParams.get('token');
      if(urlToken) {
        await openPasswordModel();
        console.log('password reset link clicked');
      }
  });
 
const updateAllTasks = async() => {
    location.reload(true);
}
 
</script>