import axios from 'axios';
import Cookies from 'js-cookie';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(async (config) => {
  const accessToken = Cookies.get('access_token');

  if (isTokenExpired(accessToken)) {
    // If the access token is expired, use the refresh token to get a new access token
    const refreshToken = Cookies.get('refresh_token');
    const response = await axios.post('/auth/refresh-token', { refresh_token: refreshToken });

    // Update the access token cookie and add it to the request
    Cookies.set('access_token', response.data.access_token, { expires: 1/3 }); // Set for 8 hours
    config.headers['Authorization'] = `Bearer ${response.data.access_token}`;
  } else {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
}, error => {
  return Promise.reject(error);
});

// Function to check if token is expired (you can use any JWT decode library)
function isTokenExpired(token) {
  if (!token) return true;

  const jwtPayload = JSON.parse(atob(token.split('.')[1]));
  const expiry = jwtPayload.exp * 1000;
  return Date.now() > expiry;
}

// Add a request interceptor
// apiClient.interceptors.request.use(config => {
//   const token = Cookies.get('access_token'); 
//   console.log("access_token", token);
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });
 
export default apiClient;