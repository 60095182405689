<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg">Manage Your Leaves Easily</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">Welcome! It seems you haven't logged any leaves yet. Our platform allows you to record your leaves effortlessly, whether it's a single day or multiple days. You can also specify reasons for each leave, helping you stay organized and informed. You have the flexibility to update or edit your leave entries at any time. Start managing your leaves now and maintain a clear overview of your time off!
    </p>
  </div>
</div>
</template>
<script setup>
</script>
 
