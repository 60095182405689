<template>
  <div class="py-4 container-fluid full-width">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
              <MiniCard @update:emitUpdateBreaks="addBreak" isAdd="true" heading="Add break manually" :count="breaksList?.todayData?.length" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <span v-if="token">
              <MiniCard heading="current week" :count="breaksList?.oneWeekData?.length" :percentage="breaksList?.oneToTwoWeekPercentage"/>
            </span>  
            </div>
          <div class="col-lg-3 col-md-6 col-12">
            <!-- <MiniCard heading="break per month" :count="todayBreaksCount"/> -->
          </div>
          <div class="col-lg-3 col-md-6 col-12">          
            <MiniCard @update:emitUpdateBreaks="addTask" isAdd="true" heading="Add Task"/>
          </div>
        </div>

        <breakInfo1 v-if="!token"></breakInfo1>
        <div class="row">
           <div class="col-lg-12 col-md-12 col-12">
            <AddBreak @update:emitTodayBreaks="getTodayBreaks" :break-types="breakTypes"></AddBreak></div>
            </div>
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12 mb-lg">
            <!-- line chart -->
            <DisplayBreakList :breaks-list="breaksList" @update:emitTodayBreaks="getTodayBreaks"></DisplayBreakList>
             <!-- <TabularDisplay v-if="breaksList" :breaks-list="breaksList" @update:emitTodayBreaks="getTodayBreaks"></TabularDisplay> -->
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <TodayPieChart :breaks-list="breaksList"></TodayPieChart>
            <!-- <carousel /> -->
             <div>
           <BreakType :break-types="breakTypes" @update:emitUpdateBreakTypes="updateBreakTypes"></BreakType>
          </div>
          </div>
        </div> 
      </div>
    </div>
    <EditBreak v-if="showEdit" @update:emitEditBreaks="updateAllBreaks"></EditBreak>
    <AddTask v-if="showTask" @update:emitEditTask="updateAllTask"></AddTask>
  </div>
</template>
<script setup> 
import { onMounted, ref, computed } from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie'; 
import MiniCard from "@/examples/Cards/MiniCard.vue";
import BreakType from "./components/breaks/BreakType.vue";
import AddTask from "./components/tasks/AddTask.vue";
import breakInfo1 from "./components/common/break-info1.vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';

import AddBreak from './components/breaks/AddBreak';
import DisplayBreakList from './components/breaks/DisplayBreakList';
import TodayPieChart from './components/breaks/TodayPieChart';
import EditBreak from './components/breaks/EditBreak.vue';
import apiClient from '../api/apiClient';
import { getBreaks } from './components/composables/Getbreaks';
import { isAuth } from './components/composables/userAuth';

const store = useStore();
let showEdit = ref(false);
let showTask = ref(false);
const userId = Cookies.get('id');
let breaksList = ref(null);
let breaksLoading = ref(false);
let breakTypes = ref(null);
store.commit("isDashboard", true);
store.state.isAbsolute = false;
  const token = computed(() => {
      return Cookies.get('access_token');;
  });
// call it also on update
    const getTodayBreaks = async () => {
      if(token.value) {
         breaksList.value = await getBreaks(userId, false, true);
      }  
      console.log("BreaksInfo after edit", breaksList.value)
    }

    const updateBreakTypes = (type) => {
      console.log("type type type", type)
      if(type) {
         breakTypes.value = type;
      }  
    }

    const updateAllBreaks = async (isAdded) => {
      console.log("isAdded",isAdded);
      if(isAdded) {
        getTodayBreaks();
      }
      showEdit.value = false;
    }

    const updateAllTask = async () => {
      showTask.value = false;
    }
    
    const addBreak = () => { 
      if(isAuth()) {
        showEdit.value = true;
      }
    }

    const addTask = () => { 
      if(isAuth()) {
        showTask.value = true;
      }
    }

    const getBreakTypes = async () => {
    try {  
          breaksLoading.value = true;
          const response = await apiClient.get('/type/get', {
          params: {
            userId: userId
          }
        });
        breakTypes.value = response.data[0].type;   
        } catch (error) {
          console.error('Error break/get:', error);
        } 
        finally {
          breaksLoading.value = false;
        }
    }

  const verifyEmail = async () => {
 

try {
// Call backend to verify token
      // fetch(`http://localhost:3000/user/verify?token=${token}`, {
      //   method: 'GET',
      // })
      const queryParams = new URLSearchParams(window.location.search);
      const urlToken = queryParams.get('token');
      if(!urlToken) {
        return false;
      }
      console.log("urlToken", urlToken);
      const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/user/verify`, {
        params: {
          token: urlToken  // Pass token as a query parameter
        }
      });
      console.log('token response', response); 
      if(response.status == 200 && response.data.message === "Email verified successfully"){
        console.log('ver9ified'); 

        Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: response.data.message,
      }); 

        if (urlToken) {
          // Store the token as needed, then update the URL
          queryParams.delete('token');
          const newUrl = queryParams.toString() ? `${window.location.pathname}?${queryParams.toString()}` : window.location.pathname;
          window.history.replaceState({}, document.title, newUrl);
          const signInKey = document.querySelector('#signin_key');
          signInKey.click();
        }

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Error updating email.',
      });
    } 
        // .then(response => response.json())
        // .then(data => {
        //   if (data.success) {
        //     alert('Email verified successfully!');
        //   } else {
        //     alert('Verification failed. The link may have expired.');
        //   }
        // })
        }
        catch (error) {
                  console.error('Error verifying email:', error);
        }
      }


    onMounted( async() => {
        console.log('token.value ' + token.value );
      if(!token.value){
          breakTypes.value =['Lunch Break', 'Short Break', 'Refreshment', 'Cigarette', 'Picking kids from School', 'Coffee Break'];
          await verifyEmail()
        } else {          
        await getBreakTypes(); 
        await getTodayBreaks();  
        }     
    }); 

    // Helper function to filter data based on date range
    // const filterByDateRange = (records, startDate, endDate) => { 
    //   return records.filter(record => {
    //     const recordDate = new Date(record.createdDate);
    //     return recordDate > startDate && recordDate <= endDate;
    //   });
    // }

    // // Helper function to get today's date at midnight
    // const getTodayDate =() => {
    //   const today = new Date();
    //   today.setUTCHours(0, 0, 0, 0);
    //   return today;
    // }

    // // Helper function to get date X days ago
    // const getDateXDaysAgo = (days) => {
    //   const date = new Date();
    //   date.setDate(date.getDate() - days);
    //   date.setUTCHours(0, 0, 0, 0);
    //   return date;
    // }
</script>
<style scoped>
.full-width {
      padding-right: 1rem;
     padding-left: 1rem;
}
</style>