<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg">Track Your Breaks and Stay Balanced!</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">It looks like you haven’t logged any breaks yet. Taking regular breaks is key to maintaining your productivity and well-being throughout the day. Start by adding your breaks, whether it’s for lunch, coffee, or a quick refreshment, and track the time, date, and reason for each one.
    <br/><br/>
    You can monitor your break patterns with our easy-to-read charts and tables, helping you stay balanced and recharge effectively during your workday. Don’t wait—log your first break now and make the most of your time!
</p>
  </div>
</div>
</template>
<script setup>
</script>
 
