<template>
  <div :id="carouselId" class="carousel slide" data-bs-ride="carousel" data-bs-interval="2300">
    <!-- Indicators/dots -->
    <div class="carousel-indicators">
      <button
        v-for="(image, index) in images"
        :key="index"
        :class="{'active': index === 0}"
        :data-bs-target="`#${carouselId}`"
        :data-bs-slide-to="index"
      ></button>
    </div>

    <!-- The slideshow/carousel -->
    <div class="carousel-inner">
      <div
        v-for="(image, index) in images"
        :key="index"
        :class="['carousel-item', { active: index === 0 }]"
      >
        <img :src="image.src" :alt="image.alt" class="d-block w-100" />
      </div>
    </div>

    <!-- Left and right controls/icons -->
    <button
      class="carousel-control-prev"
      type="button"
      :data-bs-target="`#${carouselId}`"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon"></span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      :data-bs-target="`#${carouselId}`"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon"></span>
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue';

// Props to receive the images and an optional custom ID
const props = defineProps({
  images: {
    type: Array,
    required: true,
    default: () => [],
  },
  id: {
    type: String,
    default: 'demo',
  },
});

// Generate a unique ID for the carousel instance
const carouselId = ref(props.id);
</script>

<!-- <style scoped>
.carousel {
  width: 100%;
}
</style> -->
