<template>
  <div>

    <!-- Reset Password Modal -->
    <div ref="resetPasswordModalRef" class="modal fade" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Reset Password</h5>
            <button type="button" class="border-0 bg-transparent" @click="closeDialog" aria-label="Close">
            <i class="custom-icon close-icon icon-lg"></i>
          </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updatePassword">
              <div class="mb-3">
                <label for="newPassword" class="form-label">New Password</label>
                <input
                  v-model="newPassword"
                  type="password"
                  id="newPassword"
                  minlength="5"
                  class="form-control"
                  placeholder="Enter new password"
                  required
                />
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-success w-100">Reset Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { Modal } from 'bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
const newPassword = ref('');
const resetPasswordModalRef = ref(null);
let resetPasswordModal = null;

 const updatePassword = async () => { 
try {
 
      const queryParams = new URLSearchParams(window.location.search);
      const urlToken = queryParams.get('token');
      console.log("urlToken", urlToken, newPassword);
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/user/reset-password`, {
        token: urlToken,
        newPassword: newPassword.value,
        });
      console.log('token response', response); 
      if(response.status == 201 && response.data.message === "Password Updated successfully"){
        closeDialog(); 

        Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: response.data.message,
      }); 

        if (urlToken) {
          // Store the token as needed, then update the URL
          queryParams.delete('token');
          const newUrl = queryParams.toString() ? `${window.location.pathname}?${queryParams.toString()}` : window.location.pathname;
          window.history.replaceState({}, document.title, newUrl);
          const signInKey = document.querySelector('#signin_key');
          signInKey.click();
        }

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Error updating email.',
      });
    } 
        
        }
        catch (error) {
                  console.error('Error verifying email:', error);
        }
      }

onMounted(() => {
  resetPasswordModal = new Modal(resetPasswordModalRef.value, {
    backdrop: 'static',
    keyboard: false
  });
  resetPasswordModal.show();
});

const closeDialog = () => { 
    resetPasswordModal.hide();
};

  
</script>
